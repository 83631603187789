.font-heading {  font-family: 'Montserrat', sans-serif; }
.font-body { font-family: 'Open Sans', sans-serif; }

$theme_colors: (

  c1: #FFBC15, // (Gold)
  c1-lite: #FFE315,
  c1-dark: #FF9415,

  c2: #1558FF, // (Blue)
  c2-lite: #157EFF,
  c2-dark: #1530FF,

  lite: #DCDCDC,
  dark: #323232,
  gray: #8A8A8A,

  c3: #FF151D,
  c4: #B22CFF,
  c5: #FF2CAD

);

@each $label, $hex in $theme-colors {
  :root { --#{$label}: #{$hex}; }
  .#{$label} { color: $hex; }
  .bg-#{$label} { color: $hex; }
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  font-size: 16px;
  color: var(--dark);
  background-color: var(--gray);

}
//This uses modernizr to check for required magic.
html {


  $min-font: 16;
  $max-font: 36;
  $min-view: 320;
  $max-view: 1920;

  $calc: calc( #{$min-font}px + ( #{$max-font} - #{$min-font} )
* ( ( 100vw - #{$min-view}px ) / ( #{$max-view} - #{$min-view} ) ) );

  body { font-size: $calc; }

}

h1, h2, h3, h4, h5, h6 { @extend .font-heading; }
p, blockquote, span, li { @extend .font-body; }

a {
  text-decoration: none;
  //cursor: not-allowed !important;
  color: var(--c2);
  &:hover { color: var(--c2-lite); }
  &:visited { color: var(--c2-dark); }
}

.content-styles {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { font-weight: 500; margin-bottom: 1.618rem; line-height: 1em; }
  h1, .h1 { font-size: 6.854em; }
  h2, .h2 { font-size: 4.236em; }
  h3, .h3 { font-size: 2.618em; }
  h4, .h4 { font-size: 1.618em; }
  h5, .h5 { font-size: 1em; }
  h6, .h6 { font-size: 0.618em; }
  p { margin-bottom: 1.618em; }
}



#welcome {
  background-color: var(--dark);
  position: relative;
  .sig-block {
    max-width: 960px;
    margin: auto;
  }
  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('http://media.caseydwayne.com/portfolio-site-2023/suit.jpg');
    background-size: cover;
    background-position: center;
    opacity: .6;
    z-index: 0;
  }
  .content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: var(--lite);
    p {
      margin-top: .618em
    }
    .content-wrap {
      transform: posY(-1.618em)
    }
    .description {
      opacity: 0;
      transition: opacity 3s linear;
      animation-duration: 1.3s;
      user-select: none;
      //animation: textUpFromMiddle 4s linear forwards;
    }
  }

}

%triangle {
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

%triangle-right {
  @extends %triangle;
  border-width: 0 0 10rem 10rem;
  border-color: transparent transparent #FFF transparent;
  right: auto;
  left: 0;
  border-width: 0 10rem 10rem 0;
}

$banner-width: 90;
$clip-cut: 20;

.sectionviewer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  //* { transition: all 2s linear; }
  &-section {

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: pink;
    background-size: cover;
    background-position: center;
  }
}

.section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  background-size: cover;
  background-position: center;

  &-content {
    margin-left: percentage( ( 100 - $banner-width ) / 100 );
    width: percentage( $banner-width / 100 );
    &-wrap {
      position: relative;
      padding: 1.618em;
      padding-right: 0;
      //margin-left: 10%;
      opacity: .8;
      overflow: hidden;
      //font-size: 38px;
      &::after {
        //@extends .triangle-right;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 0em;
        height: 0em;
        background: inherit;
        opacity: .5;
        border-style: solid;
        border-color: transparent transparent yellow transparent;
        border-width: 0 10em 10em 0;
        z-index: -1;
        display: none;
      }
    }
  }

  &-title {
    //background: red;
    //opacity: .5;
    font-size: 2.618em;
    font-size: 3.3684em;
    margin-bottom: .304em;
  }

  &-description {
    //background: blue;
    //opacity: .5;
    font-size: 1em;
  }

  // handle section-left orientation
  &-left {
    .section-content {
      margin-left: auto;
      margin-right: percentage( ( 100 - $banner-width ) / 100 );
      width: percentage( $banner-width / 100 );
      text-align: left;
      &-wrap {
        padding-left: 2.618em;
        clip-path: polygon( 0 0, 100% 0, percentage( ( 100 - $clip-cut ) / 100 ) 100%, 0 100% );
        &::after {
          //margin-right: -1em;
          right: 0;
          left: auto;
          border-color: transparent yellow transparent transparent;
          border-width: 10em 10em 0 0;
        }
      }
    }
  }

  // handle section-right orientation
  &-right {
    text-align: right;
    .section-content-wrap {
      //margin-left: 2.618em;
      padding-right: 2.618em;
      clip-path: polygon( 100% 0, 0 0, percentage( $clip-cut / 100 ) 100%, 100% 100% );
    }
  }

}

#contact {

  position: relative;
  height: 100%;
  background: var(--lite);
  a {
    cursor: pointer;
  }
  .content {
    text-align: center;
    height: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    pointer-events: none;
    .phone-link { color: var(--dark); }
    .last-word {

      padding: 1.618em;
      padding-bottom: 6.18em;
      h2 {
        display: inline-block;
      }
    }
    .last-word h2, .last-word .alot, .last-word a, .closer a {
      pointer-events: initial;
    }
  }

  .closer {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 4em;
    .icon {
      width: 24px;
      position: relative;
      top: 9px;
    }
    .icon, .text {
      display: inline-block;
    }
    a { cursor: pointer; }
    &-left, &-right {
      width: 50%;
      align-items: bottom;
    }
    &-left { text-align: left; background: rgba(0,255,0,.0); }
    &-right { text-align: right; background: rgba(255,0,0,.0); }
    &-left-wrap, &-right-wrap {
      padding: 1.618em;
    }
  }

}

#contact-magic {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  //background: rgba( 212, 0, 21, .4 );
  z-index: 2;
}

@media screen and (max-width: 680px){

  .section-left, .section-right {
    .section-content {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      text-align: center;
      &-wrap {
        padding-left: 2.618em;
        padding-right: 2.618em;
        opacity: .9;
        &::after {
          right: 0;
          left: auto;
          border: none;
        }
      }
    }
  }

  #contact .closer { display: none; }

  .section-title { font-size: 2.618em; }
  #marketing .section-title { font-size: 2.304em; }
  #multimedia .section-title { font-size: 2em; }
  #management .section-title { font-size: 1.8em; }

}

//animations

@keyframes wrapInLeft {

  0% {
    opacity: .8;
    transform: translateX(-100%)
  }

  100% {
    opacity: .8;
    transform: translateX(0)
  }

}

@keyframes wrapInRight {

  0% {
    opacity: .8;
    transform: translateX(100%)
  }

  100% {
    opacity: .8;
    transform: translateX(0)
  }

}


@keyframes wrapInSqueeze {

  0% {
    transform: scaleX(1.3) scaleY(0)
  }

  100% {
    transform: scaleX(1) scaleY(1)
  }

}


@keyframes textInFade {

  0% {
    opacity: 0
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 1
  }

}

@keyframes textUpFromMiddle {

  0% {
    transform: translateY(.2em);
    opacity: 0;
  }

  50% {
    opacity: 1
  }

  100% {
    transform: translateY(0)
  }

}

@keyframes textDownFromMiddle {

  0% {
    opacity: 0;
  }

  84% {
    transform: translateY(-.2em);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }

}

.section-left {
  .section-content-wrap {
    transition-delay: 1000ms;
    transition-property: opacity;
  }
  &.active {
    .section-content-wrap {
      opacity: 1;
      animation: wrapInLeft 1s cubic-bezier( 0.2, 0.15, .78, .86 ) forwards;
      &-text {
        animation-delay: 4s;
        animation: textInFade 1.2s cubic-bezier( 0.0, 0.0, .71, .54 );
      }
    }
  }
}

.section-right {
  .section-content-wrap {
    transition-delay: 1000ms !important;
    transition-property: opacity;
  }
  &.active {
    .section-content-wrap {
      transition-property: opacity;
      animation: wrapInRight 1s cubic-bezier( 0.2, 0.15, .78, .86 ) forwards;
      &-text {
        //opacity: 0;
        animation-delay: 4s;
        animation: textInFade 1.2s cubic-bezier( 0.0, 0.0, .71, .54 );
      }
    }
  }
}

.xsection.active {
  .section-content-wrap {
    &-text {
        //animation: textInFade 1.2s cubic-bezier( 0.0, 0.0, .71, .54 );
        .section-title {
          animation: textUpFromMiddle 1.2s cubic-bezier( 0.0, 0.0, .71, .54 );
          animation-fill-mode: forwards;
          //animation-delay: 2s;
        }
        .section-description {
          animation: textDownFromMiddle 1.8s cubic-bezier( 0.12, 0.16, .66, .84 );
        }
      }
  }
}

@media screen and (max-width: 680px){
  .section.active {
    .section-content-wrap {
      animation-delay: 1s;
      animation: wrapInSqueeze 1s cubic-bezier( 0.2, 0.15, .78, .86 );
      clip-path: none;
      &-text {
        //animation: textInFade 1.2s cubic-bezier( 0.0, 0.0, .71, .54 );
        .section-title {
          animation: textUpFromMiddle 2.7s cubic-bezier( 0.0, 0.0, .71, .54 );
          animation-fill-mode: forwards;
          //animation-delay: 2s;
        }
        .section-description {
          animation: textDownFromMiddle 3.3s cubic-bezier( 0.12, 0.16, .66, .84 );
        }
      }
    }
  }
}

.tester {
  background: var(--c1);
  text-align: center;
}

