.font-heading, h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
}

.font-body, p, blockquote, span, li {
  font-family: Open Sans, sans-serif;
}

:root {
  --c1: #ffbc15;
}

.c1, .bg-c1 {
  color: #ffbc15;
}

:root {
  --c1-lite: #ffe315;
}

.c1-lite, .bg-c1-lite {
  color: #ffe315;
}

:root {
  --c1-dark: #ff9415;
}

.c1-dark, .bg-c1-dark {
  color: #ff9415;
}

:root {
  --c2: #1558ff;
}

.c2, .bg-c2 {
  color: #1558ff;
}

:root {
  --c2-lite: #157eff;
}

.c2-lite, .bg-c2-lite {
  color: #157eff;
}

:root {
  --c2-dark: #1530ff;
}

.c2-dark, .bg-c2-dark {
  color: #1530ff;
}

:root {
  --lite: #dcdcdc;
}

.lite, .bg-lite {
  color: #dcdcdc;
}

:root {
  --dark: #323232;
}

.dark, .bg-dark {
  color: #323232;
}

:root {
  --gray: #8a8a8a;
}

.gray, .bg-gray {
  color: #8a8a8a;
}

:root {
  --c3: #ff151d;
}

.c3, .bg-c3 {
  color: #ff151d;
}

:root {
  --c4: #b22cff;
}

.c4, .bg-c4 {
  color: #b22cff;
}

:root {
  --c5: #ff2cad;
}

.c5, .bg-c5 {
  color: #ff2cad;
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  color: var(--dark);
  background-color: var(--gray);
  font-size: 16px;
}

html body {
  font-size: calc(1.25vw + 12px);
}

a {
  color: var(--c2);
  text-decoration: none;
}

a:hover {
  color: var(--c2-lite);
}

a:visited {
  color: var(--c2-dark);
}

.content-styles h1, .content-styles h2, .content-styles h3, .content-styles h4, .content-styles h5, .content-styles h6, .content-styles .h1, .content-styles .h2, .content-styles .h3, .content-styles .h4, .content-styles .h5, .content-styles .h6 {
  margin-bottom: 1.618rem;
  font-weight: 500;
  line-height: 1em;
}

.content-styles h1, .content-styles .h1 {
  font-size: 6.854em;
}

.content-styles h2, .content-styles .h2 {
  font-size: 4.236em;
}

.content-styles h3, .content-styles .h3 {
  font-size: 2.618em;
}

.content-styles h4, .content-styles .h4 {
  font-size: 1.618em;
}

.content-styles h5, .content-styles .h5 {
  font-size: 1em;
}

.content-styles h6, .content-styles .h6 {
  font-size: .618em;
}

.content-styles p {
  margin-bottom: 1.618em;
}

#welcome {
  background-color: var(--dark);
  position: relative;
}

#welcome .sig-block {
  max-width: 960px;
  margin: auto;
}

#welcome .backdrop {
  width: 100%;
  height: 100%;
  opacity: .6;
  z-index: 0;
  background-image: url("http://media.caseydwayne.com/portfolio-site-2023/suit.jpg");
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

#welcome .content {
  z-index: 1;
  text-align: center;
  color: var(--lite);
  position: relative;
}

#welcome .content p {
  margin-top: .618em;
}

#welcome .content .content-wrap {
  transform: posY(-1.618em);
}

#welcome .content .description {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity 3s linear;
  animation-duration: 1.3s;
}

.sectionviewer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.sectionviewer-section {
  width: 100%;
  height: 100%;
  background: pink center / cover;
  position: absolute;
  top: 0;
  left: 0;
}

.section {
  width: 100%;
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-content {
  width: 90%;
  margin-left: 10%;
}

.section-content-wrap {
  opacity: .8;
  padding: 1.618em 0 1.618em 1.618em;
  position: relative;
  overflow: hidden;
}

.section-content-wrap:after {
  content: "";
  width: 0;
  height: 0;
  background: inherit;
  opacity: .5;
  z-index: -1;
  border-top: 0 solid #0000;
  border-bottom: 10em solid #ff0;
  border-left: 0 solid #0000;
  border-right: 10em solid #0000;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.section-title {
  margin-bottom: .304em;
  font-size: 3.3684em;
}

.section-description {
  font-size: 1em;
}

.section-left .section-content {
  width: 90%;
  text-align: left;
  margin-left: auto;
  margin-right: 10%;
}

.section-left .section-content-wrap {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  padding-left: 2.618em;
}

.section-left .section-content-wrap:after {
  border-width: 10em 10em 0 0;
  border-color: #0000 #ff0 #0000 #0000;
  left: auto;
  right: 0;
}

.section-right {
  text-align: right;
}

.section-right .section-content-wrap {
  clip-path: polygon(100% 0, 0 0, 20% 100%, 100% 100%);
  padding-right: 2.618em;
}

#contact {
  height: 100%;
  background: var(--lite);
  position: relative;
}

#contact a {
  cursor: pointer;
}

#contact .content {
  text-align: center;
  height: 100%;
  z-index: 3;
  pointer-events: none;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
  position: relative;
}

#contact .content .phone-link {
  color: var(--dark);
}

#contact .content .last-word {
  padding: 1.618em 1.618em 6.18em;
}

#contact .content .last-word h2 {
  display: inline-block;
}

#contact .content .last-word h2, #contact .content .last-word .alot, #contact .content .last-word a, #contact .content .closer a {
  pointer-events: initial;
}

#contact .closer {
  width: 100%;
  margin-top: 4em;
  display: flex;
  position: absolute;
  bottom: 0;
}

#contact .closer .icon {
  width: 24px;
  position: relative;
  top: 9px;
}

#contact .closer .icon, #contact .closer .text {
  display: inline-block;
}

#contact .closer a {
  cursor: pointer;
}

#contact .closer-left, #contact .closer-right {
  width: 50%;
  align-items: bottom;
}

#contact .closer-left {
  text-align: left;
  background: #0f00;
}

#contact .closer-right {
  text-align: right;
  background: #f000;
}

#contact .closer-left-wrap, #contact .closer-right-wrap {
  padding: 1.618em;
}

#contact-magic {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 680px) {
  .section-left .section-content, .section-right .section-content {
    width: 100%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }

  .section-left .section-content-wrap, .section-right .section-content-wrap {
    opacity: .9;
    padding-left: 2.618em;
    padding-right: 2.618em;
  }

  .section-left .section-content-wrap:after, .section-right .section-content-wrap:after {
    border: none;
    left: auto;
    right: 0;
  }

  #contact .closer {
    display: none;
  }

  .section-title {
    font-size: 2.618em;
  }

  #marketing .section-title {
    font-size: 2.304em;
  }

  #multimedia .section-title {
    font-size: 2em;
  }

  #management .section-title {
    font-size: 1.8em;
  }
}

@keyframes wrapInLeft {
  0% {
    opacity: .8;
    transform: translateX(-100%);
  }

  100% {
    opacity: .8;
    transform: translateX(0);
  }
}

@keyframes wrapInRight {
  0% {
    opacity: .8;
    transform: translateX(100%);
  }

  100% {
    opacity: .8;
    transform: translateX(0);
  }
}

@keyframes wrapInSqueeze {
  0% {
    transform: scaleX(1.3)scaleY(0);
  }

  100% {
    transform: scaleX(1)scaleY(1);
  }
}

@keyframes textInFade {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes textUpFromMiddle {
  0% {
    opacity: 0;
    transform: translateY(.2em);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes textDownFromMiddle {
  0% {
    opacity: 0;
  }

  84% {
    opacity: 0;
    transform: translateY(-.2em);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-left .section-content-wrap {
  transition-property: opacity;
  transition-delay: 1s;
}

.section-left.active .section-content-wrap {
  opacity: 1;
  animation: 1s cubic-bezier(.2, .15, .78, .86) forwards wrapInLeft;
}

.section-left.active .section-content-wrap-text {
  animation: 1.2s cubic-bezier(0, 0, .71, .54) textInFade;
}

.section-right .section-content-wrap {
  transition-property: opacity;
  transition-delay: 1s !important;
}

.section-right.active .section-content-wrap {
  transition-property: opacity;
  animation: 1s cubic-bezier(.2, .15, .78, .86) forwards wrapInRight;
}

.section-right.active .section-content-wrap-text {
  animation: 1.2s cubic-bezier(0, 0, .71, .54) textInFade;
}

.xsection.active .section-content-wrap-text .section-title {
  animation: 1.2s cubic-bezier(0, 0, .71, .54) forwards textUpFromMiddle;
}

.xsection.active .section-content-wrap-text .section-description {
  animation: 1.8s cubic-bezier(.12, .16, .66, .84) textDownFromMiddle;
}

@media screen and (max-width: 680px) {
  .section.active .section-content-wrap {
    clip-path: none;
    animation: 1s cubic-bezier(.2, .15, .78, .86) wrapInSqueeze;
  }

  .section.active .section-content-wrap-text .section-title {
    animation: 2.7s cubic-bezier(0, 0, .71, .54) forwards textUpFromMiddle;
  }

  .section.active .section-content-wrap-text .section-description {
    animation: 3.3s cubic-bezier(.12, .16, .66, .84) textDownFromMiddle;
  }
}

.tester {
  background: var(--c1);
  text-align: center;
}

/*# sourceMappingURL=parcel.fdaba603.css.map */
